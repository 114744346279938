import { FormGroup, FormControl } from '@angular/forms'
import { SearchCheckboxOption, SearchDateRangeOption, SearchMultiAutoCompleteOption, SearchNumberRangeOption, SearchOptionGroup, SearchOptionType, SearchStringOption } from 'src/app/shared/components/filtering-search-component-base/filtering-search-types'
import { IconNamesEnum } from 'ngx-bootstrap-icons'
import { BankruptcyCaseGroupBasic, DistressedSituationReason, OutOfCourtSituationReason, creditorsRange, dollarRange, employeesRange, industryCheckbox, industryCheckboxWithID, organisationName, personName, revenueRange, situationTypes } from 'src/app/shared/components/filtering-search-component-base/filtering-search-common-definitions'

export const newsSearchOptionGroups: SearchOptionGroup[] = [
    {
        groupName: "News",
        visible: true,
        colour: '#58ABC6',
        options: [

            {
                icon: IconNamesEnum.BodyText,
                id: 'content',
                type: SearchOptionType.stringinput,
                name: 'Content',
                lastResort: true,
                searchString: '',
                matchingOption: 'phrase',
                matchingOptionTypes: {
                    exactPhrase: true,
                    allWords: true,
                    someWords: true,
                    booleanMode: false,
                }
            } as SearchStringOption,
            {
                icon: IconNamesEnum.Calendar4Range,
                id: 'publicationDate',
                type: SearchOptionType.daterange,
                name: 'Publication Date',
                minDate: new Date(),
                maxDate: new Date(),
            } as SearchDateRangeOption,

            {
                icon: IconNamesEnum.List,
                id: 'Situations',
                type: SearchOptionType.checkboxes,
                name: 'Situation type',
                checkboxes: [
                    {
                        name: 'Bankruptcy Case',
                        value: '1',
                        checked: true,
                    },
                    {
                        name: 'Distressed',
                        value: '2',
                        checked: true,
                    },
                    {
                        name: 'Exit',
                        value: '3',
                        checked: true,
                    },
                    {
                        name: 'Out-of-Court Restructuring',
                        value: '4',
                        checked: true,
                    },
                ]
            } as SearchCheckboxOption,
            {
                icon: IconNamesEnum.FileEarmarkDiff,
                id: 'Story Sources',
                name: 'Story Sources',
                type: SearchOptionType.checkboxes,
                checkboxes: [
                    {value: '5', name: 'Bankruptcy: Docket'},
                    {value: '6', name: 'Bankruptcy: Hearing Coverage'},
                    {value: '2', name: 'Distressed: Company Press Release'},
                    {value: '4', name: 'Distressed: News Service'},
                    {value: '3', name: 'Distressed: Ratings Agency'},
                    {value: '1', name: 'Distressed: SEC Filings (8K, 10K, 10Q, etc'},
                    {value: '7', name: 'Proprietary'},
                    {value: '8', name: 'Other'}
                ],
            },
            {
                icon: IconNamesEnum.FileEarmarkCheck,
                id: 'Story Types',
                name: 'Story Types',
                type: SearchOptionType.checkboxes,
                checkboxes: [

                    {value: '3', name: 'Bankruptcy: 1113/1114 CBA'},
                    {value: '10', name: 'Bankruptcy: 363 Asset Sale'},
                    {value: '7', name: 'Bankruptcy: Automatic Stay Relief/Dispute'},
                    {value: '21', name: 'Bankruptcy: Claims Transfers'},
                    {value: '20', name: 'Bankruptcy: Committee'},
                    {value: '8', name: 'Bankruptcy: Competing Plans'},
                    {value: '19', name: 'Bankruptcy: Confirmation/Effective'},
                    {value: '14', name: 'Bankruptcy: DIP'},
                    {value: '16', name: 'Bankruptcy: Exclusivity'},
                    {value: '15', name: 'Bankruptcy: First Day Filings'},
                    {value: '6', name: 'Bankruptcy: Intercreditor Dispute'},
                    {value: '17', name: 'Bankruptcy: KEIP/KERP'},
                    {value: '11', name: 'Bankruptcy: Plan'},
                    {value: '22', name: 'Bankruptcy: Plan Summary'},
                    {value: '13', name: 'Bankruptcy: Retention'},
                    {value: '18', name: 'Bankruptcy: Rights Offering'},
                    {value: '12', name: 'Bankruptcy: Rule 2019'},
                    {value: '9', name: 'Bankruptcy: Store Closing'},
                    {value: '5', name: 'Bankruptcy: Venue Dispute'},
                    {value: '4', name: 'Bankruptcy: Winddown/Liquidation'},
                    {value: '2', name: 'Distressed: Aggregation'},
                    {value: '1', name: 'Distressed: Proprietary'},
                    {value: '23', name: 'Other'}
                ],
            },
            {
                ...industryCheckboxWithID,
                id: 'Industries',
                name: 'Industries',
            } as SearchCheckboxOption,
            {
                ...organisationName,
                lastResort: true,
            }  as SearchMultiAutoCompleteOption,
            {
                ...personName,
                lastResort: true,
            }  as SearchMultiAutoCompleteOption,

        ]
    },
    {
        groupName: "Situations",
        visible: true,
        colour: '#009640',
        options: [
            {
                icon: IconNamesEnum.InputCursorText,
                id: 'entity_name',
                name: 'Name',
                type: SearchOptionType.multiautocomplete,
                required: [],
                lastResort: true,
                tableColumn: {
                    visible: true,
                },
                items: [],
                filterby: 'role',
                searchServiceParameter: {
                    area: 'situations',
                    parameters: {
                        entity_name: {
                            name: "name",
                            type: "stringinput",
                            format: 'autocomplete',
                            returndata: 'brief',
                        }
                    },
                    window: {
                        start: 1,
                        size: 5,
                    }
                },
            }  as SearchMultiAutoCompleteOption,
            {
                ...DistressedSituationReason,
                depends: {
                    'Situations': [ '2' ]
                },
                tableColumn: {
                    visible: true,
                },
            } as SearchCheckboxOption,
            {
                ...OutOfCourtSituationReason,
                depends: {
                    'Situations': [ '4' ]
                },
                tableColumn: {
                    visible: true,
                },
            } as SearchCheckboxOption,
            {
                ...structuredClone(dollarRange),
                id: 'assets_range',
                name: 'Assets Range',
                tableColumn: {
                    visible: true,
                },

            } as SearchCheckboxOption,
            {
                ...structuredClone(dollarRange),
                id: 'liabilities_range',
                name: 'Liabilities Range',
                tableColumn: {
                    visible: true,
                },
            } as SearchCheckboxOption,
            {
                ...revenueRange,
                tableColumn: {
                    visible: true,
                },
            },
            {
                ...employeesRange,
                tableColumn: {
                    visible: true,
                },
            },
            {
                ...creditorsRange,
                tableColumn: {
                    visible: true,
                },
            }
        ]
    },
    {
        ...BankruptcyCaseGroupBasic,
        depends: undefined,
        dependsMust: {
            "Situations": ['1']
        },
    }
]
